import { defineStore } from "pinia";
import axios from "axios";

export const useCartStore = defineStore("cart", {
    state: () => ({
        items: [],
        packaging: {},
        deliveryMethod: "pickup",
        discountRate: 0.15,
    }),
    actions: {
        async addToCart(product) {
            const minOrderQuantity = product.min_order_quantity || 1;
            const existingItemIndex = this.items.findIndex(
                (item) => item.id === product.id
            );

            if (existingItemIndex !== -1) {
                const currentQuantity = this.items[existingItemIndex].quantity;
                this.items[existingItemIndex].quantity = Math.max(
                    currentQuantity + 1,
                    minOrderQuantity
                );
            } else {
                this.items.push({ ...product, quantity: minOrderQuantity });
            }

            await this.calculatePackaging(product.id);
            this.saveCartToLocalStorage();
        },
        async validatePrices() {
            try {
                const response = await axios.post("/api/cart/validate-prices", {
                    items: this.items,
                });
                console.log('validatePrices response', response)
                
                if (response.data.updated) {
                    this.items = response.data.items;
                    this.saveCartToLocalStorage();
                    throw new Error("Ціни оновлено, перевірте кошик перед замовленням.");
                }
            } catch (error) {
                console.error("Ошибка проверки цен:", error);
                throw new Error("Не удалось проверить актуальные цены.");
            }
        },
        removeFromCart(productId) {
            this.items = this.items.filter((item) => item.id !== productId);
            delete this.packaging[productId];
            this.saveCartToLocalStorage();
        },
        async updateQuantity(productId, quantity) {
            const item = this.items.find((item) => item.id === productId);
            if (item) {
                const minOrderQuantity = item.min_order_quantity || 1;
                item.quantity = Math.max(quantity, minOrderQuantity);

                await this.calculatePackaging(productId);
                this.saveCartToLocalStorage();
            }
        },
        async calculatePackaging(productId) {
            try {
                const response = await axios.get(
                    `/api/products/${productId}/packaging-rule`
                );
                const rules = response.data;

                const item = this.items.find((item) => item.id === productId);

                if (!item || !Array.isArray(rules) || rules.length === 0) {
                    delete this.packaging[productId];
                    return;
                }

                let remainingQuantity = item.quantity;
                let totalPackagingCost = 0;
                let packagingDetails = [];

                // Сортируем правила по убыванию max_portions, чтобы сначала использовать большие упаковки
                const sortedRules = rules.sort(
                    (a, b) =>
                        (b.packaging_rule.max_portions || Infinity) -
                        (a.packaging_rule.max_portions || Infinity)
                );

                // Проходим по каждому правилу и добавляем упаковки до тех пор, пока порции не будут полностью распределены
                sortedRules.forEach((rule) => {
                    if (remainingQuantity <= 0) return;

                    const {
                        max_portions: maxPortions = Infinity,
                        min_portions: minPortions = 1,
                    } = rule.packaging_rule;

                    // Пока количество порций больше минимального порога, продолжаем добавлять упаковки
                    while (remainingQuantity >= minPortions) {
                        // Если оставшееся количество порций больше максимального порога, добавляем упаковку на maxPortions
                        const applicablePackages = Math.min(
                            remainingQuantity,
                            maxPortions
                        );

                        packagingDetails.push({
                            type: rule.package.name,
                            price: rule.package.price,
                            quantity: 1, // Добавляем одну упаковку на каждую итерацию
                        });

                        totalPackagingCost += rule.package.price;
                        remainingQuantity -= applicablePackages;
                    }
                });

                // Сохраняем результат расчета упаковки
                this.packaging[productId] = {
                    details: packagingDetails,
                    totalPrice: totalPackagingCost,
                };
            } catch (error) {
                console.error("Failed to load packaging rule:", error);
            }
        },
        loadCartFromLocalStorage() {
            const storedCart = localStorage.getItem("cart");

            if (storedCart) {
                try {
                    this.items = JSON.parse(storedCart);
                } catch (error) {
                    console.error(
                        "Failed to parse cart items from localStorage:",
                        error
                    );
                    this.items = [];
                }
            }
            const storedPackaging = localStorage.getItem("packaging");
            if (storedPackaging) {
                try {
                    this.packaging = JSON.parse(storedPackaging);
                } catch (error) {
                    console.error(
                        "Failed to parse packaging from localStorage:",
                        error
                    );
                    this.packaging = {};
                }
            }
            const storedDeliveryMethod = localStorage.getItem("deliveryMethod");
            if (storedDeliveryMethod) {
                this.$patch({
                    deliveryMethod: storedDeliveryMethod,
                });
            }
        },
        saveCartToLocalStorage() {
            localStorage.setItem("cart", JSON.stringify(this.items));
            localStorage.setItem("packaging", JSON.stringify(this.packaging));
            localStorage.setItem("deliveryMethod", this.deliveryMethod);
        },
        setDeliveryMethod(method) {
            this.deliveryMethod = method;
            this.saveCartToLocalStorage();
        },
    },
    getters: {
        totalItems: (state) => {
            const total = state.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return total;
        },

        totalPrice: (state) => {
            if (!state.items || state.items.length === 0) {
                return 0;
            }
            const total = state.items.reduce(
                (total, item) =>
                    total + Number(item.price || 0) * item.quantity,
                0
            );
            return total;
        },

        discountAmount: (state, getters) => {
            if (!getters || getters.totalPrice === undefined) {
                console.log(
                    "discountAmount: 0 (getters or totalPrice is undefined)"
                );
                return 0;
            }
            const totalPrice = parseFloat(getters.totalPrice) || 0;

            const discount =
                state.deliveryMethod === "pickup"
                    ? parseFloat((totalPrice * state.discountRate).toFixed(2))
                    : 0;

            return discount;
        },

        totalPriceWithDiscount: (state, getters) => {
            if (
                getters.totalPrice === undefined ||
                getters.discountAmount === undefined
            ) {
                console.log("totalPriceWithDiscount: 0 (undefined detected)");
                return 0;
            }
            const totalPrice = parseFloat(getters.totalPrice) || 0;
            const discount = parseFloat(getters.discountAmount) || 0;
            const totalPriceWithDiscount = parseFloat(
                (totalPrice - discount).toFixed(2)
            );

            return totalPriceWithDiscount;
        },

        totalPackagingPrice: (state) => {
            const totalPackaging = Object.values(state.packaging).reduce(
                (total, pack) => total + (pack?.totalPrice || 0),
                0
            );
            return totalPackaging;
        },

        getPackagingDetails: (state) => (productId) => {
            const details = state.packaging[productId]?.details || [];

            return details;
        },

        allPackagingDetails: (state) => {
            const allDetails = Object.keys(state.packaging).map(
                (productId) => ({
                    productId,
                    details: state.packaging[productId]?.details || [],
                    totalPrice: state.packaging[productId]?.totalPrice || 0,
                })
            );

            return allDetails;
        },
        totalCost: (state, getters) => {
            if (!getters || getters === undefined) {
                console.log("Error: getters is undefined");
                return 0;
            }

            const priceWithDiscount =
                parseFloat(getters.totalPriceWithDiscount) || 0;

            const packagingCost = parseFloat(getters.totalPackagingPrice) || 0;

            const totalCost = parseFloat(
                (priceWithDiscount + packagingCost).toFixed(2)
            );

            return totalCost;
        },
    },
});
